import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  getFirestore
} from "firebase/firestore";
import { IS_IN_DEVELOPMENT } from "../functions-origin";
import { fetchAndActivate, getRemoteConfig } from "firebase/remote-config";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyBMPBSGiujLeSu6qmjZEe9yeOgPf0s8HZA",
  authDomain: "ar-mycodr.firebaseapp.com",
  projectId: "ar-mycodr",
  storageBucket: "ar-mycodr.appspot.com",
  messagingSenderId: "749477034189",
  appId: "1:749477034189:web:3a02e0d81d4bf1620c5184",
  measurementId: "G-YJJJQCVVZJ"
};

declare global {
  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}

// Initialize Firebase
export const app = ((window.FIREBASE_APPCHECK_DEBUG_TOKEN = IS_IN_DEVELOPMENT), initializeApp(firebaseConfig));

initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6Le7wwMpAAAAAG580bVcBG52UkY96qS1SGaK84gn"),
  isTokenAutoRefreshEnabled: true,
});

export const auth = getAuth(app);
export const firestore = getFirestore(app);

// export const analytics = getAnalytics(app);
// export const logAnalytic = (message: string) => {
//   logEvent(analytics, message);
// };

// Initialize Remote Config and get a reference to the service
export const remoteConfig = getRemoteConfig(app);
// Determine how long data is cached - in milisecs
remoteConfig.settings.minimumFetchIntervalMillis = 10000;
// Assign the default values
remoteConfig.defaultConfig = require('./remote-config-defaults.json');
// Get latest from server
fetchAndActivate(remoteConfig);