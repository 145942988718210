import { useEffect, useState } from "react";
import { Stack, Button, Transition } from "@mantine/core";
import { getCodeData, logAnalytic } from "../services/firestore-endpoints";
import PageNotFound from "./Error";
import { useTranslation } from "react-i18next";
import { timeout } from "..";
import { useParams } from "react-router-dom";
import RedirectVCard from "./Redirect/RedirectVcard";
import { isMobile, isTablet } from "react-device-detect";
import moment from "moment";

export default function Redirect({ setHeaderShown }) {
  const { t } = useTranslation();
  const { code } = useParams();

  useEffect(() => {
    document.title = t("redirect.title");
    setHeaderShown(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [docData, setDocData] = useState({});
  const [vcardData, setVcardData] = useState(null);
  const [error, setError] = useState(null);

  const lastPageUrl = document.referrer;

  useEffect(() => {
    getCodeData(code, (data) => {
      setIsLoading(false);
      setDocData(data);
      if (data !== null && (data?.auto_launch ?? true)) {
        checkForPreLink(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const [timeoutRemaining, setTimeoutRemaining] = useState(6);

  useEffect(() => {
    async function countdown() {
      if (timeoutRemaining > 0) {
        await timeout(1000);
        setTimeoutRemaining((c) => c - 1);
      }
    }
    countdown();
  }, [timeoutRemaining]);

  const checkForPreLink = (data) => {
    const prelink = data.prelink;
    if (prelink) {
      // prelink found
      const activeUntil = moment(prelink.active_until.toDate());

      if (moment().isAfter(activeUntil)) {
        // current date is after the active date load the main url
        commitAction(data);
      } else {
        // reload
        commitActionUrl(prelink);
      }
    } else {
      // prelink not found
      commitAction(data);
    }
  };

  const commitAction = (data) => {
    switch (data.type ?? "") {
      // URL
      case "url":
        commitActionUrl(data);
        break;

      case "wifi":
        const ssid = data?.wifi?.ssid;
        const password = data?.wifi?.password;
        const wifiType = data?.wifi?.ssid;
        if ((isMobile || isTablet) && ssid && password && wifiType) {
          // Wifi
          window.open(`https://custqrd.web.app/r/wifi?ssid=${ssid}&pw=${password}&t=${wifiType}`, "_self");
        } else {
          // Not mobile or tablet
          setError({ title: t("errorType.unsupported"), message: t("errorType.wifi.desktop.message") });
        }
        break;

      // VCARD
      case "vcard":
        logAnalytic(code, lastPageUrl, data.url, () => setVcardData(data));
        break;

      default:
        setError({});
        break;
    }
  };

  const commitActionUrl = (data) => {
    if (data?.launch_new_tab ?? false) {
      logAnalytic(code, lastPageUrl, data.url, () => {
        window.open(data.url, "_blank");
        if (data?.auto_close ?? false) {
          window.close();
        }
      });
    } else {
      logAnalytic(code, lastPageUrl, data.url, () => {
        window.location.replace(data.url);
      });
    }
  };

  if (error) {
    return <PageNotFound title={error.title} message={error.message} />;
  }

  if (vcardData !== null) {
    return <RedirectVCard data={vcardData} />;
  }

  if (isLoading || docData !== null) {
    return (
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Stack align="center" gap="lg">
          <Transition
            mounted={docData !== null && timeoutRemaining === 0}
            transition="fade"
            duration={400}
            timingFunction="ease"
          >
            {(styles) => (
              <Button style={styles} size="lg" onClick={checkForPreLink(docData)}>
                {t("redirect.openManually")}
              </Button>
            )}
          </Transition>
        </Stack>
      </div>
    );
  }

  return <PageNotFound />;
}
