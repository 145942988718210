import { useEffect, useState } from "react";
import { getMyCodeAnalytics, getMyCodes } from "../../../services/firestore-endpoints";
import { User } from "firebase/auth";
import { QueryDocumentSnapshot } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { SimpleGrid, Transition } from "@mantine/core";
import { AnanlyticLinkProp } from "../../../models/RedirectModel";
import { LinkModule } from "../../../components/LinkModule";
import { AnalyticProp } from "../../../models/Analytics";
import { useNavigate } from "react-router-dom";

export default function AccountManageLinks(props: {
  user: User;
  onAnalyticSelect: (analytics: AnalyticProp[]) => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [myCodes, setMyCodes] = useState<QueryDocumentSnapshot[]>([]);
  const [myCodesAnalytics] = useState<AnanlyticLinkProp[]>([]);

  useEffect(() => {
    document.title = `${t("menu.dashboard")} - ${t("title")}`;

    if (myCodes.length === 0) {
      getMyCodes(props.user.uid).then((c) => {
        if (c.docs.length > 0) {
          c.docs.map((ad) =>
            getMyCodeAnalytics(ad.id)
              .then((add) => {
                if (add.length > 0 && !myCodesAnalytics.map((i) => i.id).includes(ad.id)) {
                  myCodesAnalytics.push({ id: ad.id, data: add });
                }
              })
              .then(() => {
                setMyCodes(c.docs);
                setIsLoading(false);
              })
          );
        } else {
          setMyCodes(c.docs);
          setIsLoading(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stats = myCodes.map((d) => {
    const anas = myCodesAnalytics.filter((i) => i.id === d.id)[0]?.data;
    return (
      <LinkModule
        key={d.id}
        id={d.id}
        type={d.data().type}
        analytics={anas}
        onNavigate={() => {
          props.onAnalyticSelect(anas);
          navigate(`/account/link/${d.id}`);
        }}
      />
    );
  });

  return (
    <>
      <Transition mounted={isLoading} transition="fade" duration={200} enterDelay={200} timingFunction="ease">
        {(styles) => (
          <SimpleGrid style={styles} cols={{ base: 2, xs: 2, md: 4 }}>
            {["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"].map((e) => {
              return <LinkModule key={e} id={e} type="url" isLoading />;
            })}
          </SimpleGrid>
        )}
      </Transition>

      <Transition mounted={!isLoading} transition="fade" duration={200} enterDelay={200} timingFunction="ease">
        {(styles) => (
          <SimpleGrid style={styles} cols={{ base: 1, xs: 2, md: 4 }}>
            {stats}
          </SimpleGrid>
        )}
      </Transition>
    </>
  );
}
