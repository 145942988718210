import { Paper, Stack, UnstyledButton, Text } from "@mantine/core";
import { VCardEmailProp, VCardFieldProp, VCardThemeProp } from "../models/VCardModel";

export function VCardButton(props: {
  theme: VCardThemeProp;
  field?: VCardFieldProp;
  email?: VCardEmailProp;
  isPhone?: Boolean;
}) {
  const field = props.field;
  const email = props.email;

  const generateEmailUrl = () => {
    var mail = `mailto:${email?.value}`;

    if (email?.subject) {
      mail = `${mail}?subject=${encodeURI(email.subject)}`;
    }
    if (email?.body) {
      mail = `${mail}&body=${encodeURI(email.body)}`;
    }
    return mail;
  };

  return (
    <UnstyledButton
      onClick={() => {
        if (email) {
          window.location.href = generateEmailUrl();
        }
        if (field && props.isPhone) {
          window.location.href = `tel:${field?.value}`;
        } else if (field) {
          window.open(field.value, "_blank");
        }
      }}
    >
      <Paper withBorder p="sm" pl="md" radius={16}>
        <Stack gap={0} align="start">
          {(email?.type || field?.type) && <Text c="dimmed">{email?.type ?? field?.type}</Text>}
          <Text>
            {email?.value ?? field?.user ?? (field?.value.includes("//") ? field?.value.split("//")[1] : field?.value)}
          </Text>
        </Stack>
      </Paper>
    </UnstyledButton>
  );
}
