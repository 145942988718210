import { AppShell, Container } from "@mantine/core";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/charts/styles.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import PageNotFound from "./pages/Error";
import Redirect from "./pages/Redirect";
import { useEffect, useState } from "react";
import { User } from "firebase/auth";
import { auth } from "./services/firebase-config";
import FullScreenLoader from "./pages/FullScreenLoader";
import AuthLogin from "./pages/Auth/login";
import AppHeader from "./components/AppHeader";
import AccountManageLinks from "./pages/Account/Links/ManageLinks";
import AccountProfile from "./pages/Account/Profile";
import { AnalyticProp } from "./models/Analytics";
import AccountLinkDashboard from "./pages/Account/Links/Dashboard";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);
  const [isHeaderShown, setIsHeaderShown] = useState<boolean>(true);

  const [linkAnalytic, setLinkAnalytic] = useState<AnalyticProp[]>([]);

  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      if (user) {
        localStorage.setItem("user_id", user.uid);
        user.getIdToken().then(() => {
          // setCurrentUserToken(tokenId);
          setCurrentUser(user);
          setIsLoading(false);
        });
      } else {
        localStorage.removeItem("user_id");
        setCurrentUser(undefined);
        setIsLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <FullScreenLoader color="primary.5" />;

  return (
    <BrowserRouter>
      <AppShell header={{ height: isHeaderShown ? 68 : 0 }} padding="md">
        {isHeaderShown && (
          <AppShell.Header p="xs" style={{ backgroundColor: "#FFC20F" }}>
            <AppHeader user={currentUser} />
          </AppShell.Header>
        )}
        <AppShell.Main>
          <Container>
            <Routes>
              <Route path="/" index element={<Home />} />
              <Route path="/r/:code" element={<Redirect setHeaderShown={setIsHeaderShown} />} />

              {/* USER */}
              {!currentUser && <Route path="/login" element={<AuthLogin />} />}
              {currentUser && (
                <>
                  <Route path="/account" element={<AccountProfile user={currentUser} />} />
                  <Route
                    path="/account/links"
                    element={<AccountManageLinks user={currentUser} onAnalyticSelect={setLinkAnalytic} />}
                  />
                  <Route
                    path="/account/link/:linkId"
                    element={<AccountLinkDashboard user={currentUser} analytics={linkAnalytic} />}
                  />
                </>
              )}

              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Container>
        </AppShell.Main>
      </AppShell>
    </BrowserRouter>
  );
}

export default App;
