import { Button, LoadingOverlay, Paper, Stack, TextInput } from "@mantine/core";
import { updateProfile, User } from "firebase/auth";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function AccountProfile(props: { user: User }) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayName, setDisplayName] = useState<string | null>(props.user.displayName);

  const saveChanges = () => {
    setIsLoading(true);
    updateProfile(props.user, { displayName: displayName })
      .then(() => window.location.reload())
      .catch((e) => {
        setIsLoading(false);
        alert(e.message);
      });
  };

  return (
    <Paper
      withBorder
      p="xl"
      radius="lg"
      style={{
        position: "absolute",
        left: "50%",
        top: "25%",
        transform: "translate(-50%, -50%)",
      }}
      w={400}
    >
      <Stack>
        <LoadingOverlay visible={isLoading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
        <TextInput
          label="Display Name"
          value={displayName ?? ""}
          onChange={(e) => setDisplayName(e.currentTarget.value)}
        />
        <Button
          size="md"
          disabled={(displayName?.length ?? 0) === 0 || props.user.displayName === displayName}
          onClick={saveChanges}
        >
          {t("general.update")}
        </Button>
      </Stack>
    </Paper>
  );
}
