import { Loader, Stack, Text } from "@mantine/core";

export default function FullScreenLoader(props: { color: string; title?: string }) {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <Stack align="center">
        <Loader size="xl" color={props.color} />
        {props.title && <Text>{props.title}</Text>}
      </Stack>
    </div>
  );
}
