/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, LoadingOverlay, Paper, PasswordInput, Stack, TextInput } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { auth } from "../../services/firebase-config";
import { signInWithEmailAndPassword } from "firebase/auth";

export default function AuthLogin(props: {}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `${t("login.title")} - ${t("title")}`;
  }, []);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");

  const attemptLogin = async () => {
    setIsLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((cred) => {
        if (cred.user) {
          navigate("/account/links");
        } else {
          setIsLoading(false);
          setError(t("error.unknown"));
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.message);
        // setError(error.code.split("auth/")[1]);
      });
  };

  return (
    <Paper
      withBorder
      p="xl"
      radius="lg"
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
      w={400}
    >
      <LoadingOverlay visible={isLoading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      <Stack>
        {/* <Button color="black" size="md">
         Continue with Apple
      </Button> */}
        <TextInput
          size="md"
          label={t("general.email")}
          placeholder={t("general.email")}
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
          error={error.length > 0 ? " " : ""}
        />
        <PasswordInput
          size="md"
          label={t("general.password")}
          placeholder={t("general.password")}
          value={password}
          onChange={(e) => setPassword(e.currentTarget.value)}
          error={error}
        />
        <Button
          disabled={email.length === 0 || password.length === 0}
          mt="md"
          fullWidth
          size="md"
          onClick={attemptLogin}
        >
          Login
        </Button>
      </Stack>
    </Paper>
  );
}
