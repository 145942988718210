import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { MantineProvider } from "@mantine/core";
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));

export function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay));
}

root.render(
  <MantineProvider
    defaultColorScheme="auto"
    theme={{
      primaryColor: "custqrd",
      primaryShade: 9,
      colors: {
        custqrd: [
          "#fffae1",
          "#fff3cc",
          "#ffe69b",
          "#ffd864",
          "#ffcc38",
          "#ffc41c",
          "#ffc109",
          "#e3a900",
          "#ca9600",
          "#af8100",
        ],
      },
      fontFamily: "Josefin Sans, Poppins",
    }}
  >
    <App />
  </MantineProvider>
);
