import { ActionIcon, Avatar, Container, Flex, Group, Menu, rem, Title } from "@mantine/core";
import { signOut, User } from "firebase/auth";
import { IconLink, IconLogout2, IconPlus, IconUser, IconWifi } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { auth } from "../services/firebase-config";

export default function AppHeader(props: { user: User | undefined }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container>
      <Flex justify="space-between" align="center">
        <Title fz={40} fw={900} fs="italic" c="black">
          {t("title")}
        </Title>

        {props.user && (
          <Group justify="center" align="center">
            <Menu shadow="lg" width={150} withArrow arrowPosition="center">
              <Menu.Target>
                <ActionIcon variant="filled">
                  <IconPlus style={{ width: "80%", height: "80%" }} stroke={2} />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>{t("menu.link.description")}</Menu.Label>
                <Menu.Item leftSection={<IconLink style={{ width: rem(18), height: rem(18) }} />} onClick={() => {}}>
                  {t("menu.link.url")}
                </Menu.Item>
                <Menu.Item leftSection={<IconWifi style={{ width: rem(18), height: rem(18) }} />} onClick={() => {}}>
                  {t("menu.link.wifi")}
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
            <Menu shadow="lg" width={200} withArrow arrowPosition="center">
              <Menu.Target>
                <Avatar mb={2} key={props.user.displayName} name={props.user.displayName ?? ""} color="initials" />
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>{props.user.displayName}</Menu.Label>
                <Menu.Divider />
                <Menu.Item
                  leftSection={<IconLink style={{ width: rem(16), height: rem(16) }} />}
                  onClick={() => navigate("/account/links")}
                >
                  {t("menu.dashboard")}
                </Menu.Item>
                <Menu.Item
                  leftSection={<IconUser style={{ width: rem(16), height: rem(16) }} />}
                  onClick={() => navigate("/account")}
                >
                  {t("menu.profile")}
                </Menu.Item>

                <Menu.Divider />

                <Menu.Item
                  color="red"
                  leftSection={<IconLogout2 style={{ width: rem(16), height: rem(16) }} />}
                  onClick={() => signOut(auth).then(() => navigate("/"))}
                >
                  {t("menu.logout")}
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        )}
      </Flex>
    </Container>
  );
}
