/* eslint-disable no-self-compare */
import { useEffect } from "react";
import { Title, Stack } from "@mantine/core";
import { useTranslation } from "react-i18next";

export default function Home() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `${t("home.title")} - ${t("title")}`;
  });

  return (
    <Stack
      align="center"
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Title fz={72}>{t("error.comingsoon.title")}</Title>
    </Stack>
  );
}
