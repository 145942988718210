import VCard from "vcard-creator";
import { VCardProp } from "../models/VCardModel";

export function generateVCard(card: VCardProp) {
  const newVCard = new VCard();

  if (card.image) {
    newVCard.addPhotoURL(card.image);
  }
  // Name
  if (card.name) {
    newVCard.addName(
      card.name.last ?? undefined,
      card.name.first ?? undefined,
      card.name.additional ?? undefined,
      card.name.prefix ?? undefined,
      card.name.suffix ?? undefined
    );
  }
  if (card.name?.nickname) {
    newVCard.addNickname(card.name.nickname);
  }
  // Addresses
  if (card.addresses) {
    card.addresses.forEach((a) => {
      newVCard.addAddress(
        a.name ?? undefined,
        a.extended ?? undefined,
        a.street ?? undefined,
        a.city ?? undefined,
        a.region ?? undefined,
        a.zip ?? undefined,
        a.country ?? undefined,
        a.type ?? undefined
      );
    });
  }
  // Phones
  if (card.phones) {
    card.phones.forEach((p) => {
      newVCard.addPhoneNumber(p.value ?? undefined, p.type ?? undefined);
    });
  }
  if (card.urls) {
    card.urls.forEach((u) => {
      newVCard.addURL(u.value, u.type ?? undefined);
    });
  }
  // Socials
  if (card.socials) {
    card.socials.forEach((s) => {
      newVCard.addSocial(s.value, s.type ?? "Account", s.user ?? undefined);
    });
  }
  if (card.job) {
    newVCard.addJobtitle(card.job);
  }
  if (card.note) {
    newVCard.addNote(card.note ?? undefined);
  }

  var FileSaver = require("file-saver");
  var data = new Blob([newVCard.getOutput()], {
    type: `${newVCard.getContentType()};charset=${newVCard.getCharset()}`,
  });
  FileSaver.saveAs(
    data,
    `${card.name?.first?.toLocaleLowerCase()}_${card.name?.last?.toLocaleLowerCase()}.${newVCard.getFileExtension()}`
  );
}
