import { useEffect } from "react";
import { Title, Text, Button, Stack } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function PageNotFound(props: { title?: string; message?: string }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const title = props.title ?? t("error.pageNotFound.title");
  const message = props.message ?? t("error.pageNotFound.message");

  useEffect(() => {
    document.title = `${t("error.pageNotFound.slug")} - ${t("title")}`;
    // document.body.style.backgroundColor = "#FFC20F";
  });

  return (
    <Stack
      align="center"
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Title fz={96}>{title}</Title>
      <Text size="lg">{message}</Text>
      <Button variant="filled" size="lg" onClick={() => navigate(`/`)}>
        {t("error.pageNotFound.button")}
      </Button>
    </Stack>
  );
}
