import { browserName, browserVersion, isDesktop, isMobile, isTablet, osName, osVersion } from "react-device-detect";
import { firestore } from "./firebase-config";
import {
  collection,
  doc,
  getDoc,
  addDoc,
  getDocs,
  query,
  where,
  orderBy,
  updateDoc,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { IS_IN_DEVELOPMENT } from "../functions-origin";
import { RedirectProp } from "../models/RedirectModel";
import { AnalyticProp } from "../models/Analytics";

// COLLECTIONS
const endpointCodes = () => {
  return collection(firestore, "codes");
};
const endpointCodeAnalytics = (id: string) => {
  return collection(firestore, "codes", id, "analytics");
};

export const getCodeData = async (code: string, completion: (data: any) => void) => {
  const docData = await getDoc(doc(endpointCodes(), code));

  if (docData.exists()) {
    completion(docData.data());
  } else {
    completion(null);
  }
};

export const logAnalytic = async (
  code: string,
  fromUrl: string | null,
  toUrl: string,
  completion: (data?: any) => void
) => {
  if (IS_IN_DEVELOPMENT) {
    // in dev mode
    completion();
  } else {
    // react is in production mode

    const data: AnalyticProp = {
      created: Timestamp.fromDate(new Date()),
      is_mobile: isMobile,
      is_tablet: isTablet,
      is_desktop: isDesktop,
      os_version: osVersion,
      os_name: osName,
      browser: browserName,
      browser_version: browserVersion,
      redirected_to: toUrl,
      from_url: fromUrl,
    };

    await addDoc(collection(firestore, "codes", code, "analytics"), data).then((d) => completion(d.id));
  }
};

export const getLink = async (code: string) => {
  return getDoc(doc(endpointCodes(), code)).then((d) => {
    return d?.data() as RedirectProp;
  });
};
export const updateLink = async (code: string, data: RedirectProp) => {
  return updateDoc(doc(endpointCodes(), code), {
    ...data,
    updated: serverTimestamp(),
  });
};

export const getMyCodes = (userId: string) => {
  return getDocs(query(endpointCodes(), where("created_by", "==", userId), orderBy("created", "desc")));
};
export const getMyCodeAnalytics = (id: string) => {
  return getDocs(query(endpointCodeAnalytics(id), orderBy("created", "asc"))).then((d) =>
    d.docs.map((doc) => doc.data() as AnalyticProp)
  );
};
